import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import {JsonService} from '../../../services/core/json.service';
import {ProposalApiResources} from './proposal.constants';

@Injectable({
  providedIn: 'root'
})


export class ProposalDataService {
  private baseURL:any;

  constructor(
    private http: HttpClient,
    private jsonService: JsonService,
  ) {
    this.baseURL = ProposalApiResources; 
  }

  list(model: any): Observable<any> {
    const endPoint = `${this.baseURL.list}`;
    return this.http.post<any>(endPoint, model)
                    .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }

  search(model: any): Observable<any> {
    const endPoint = `${this.baseURL.search}`;
    return this.http.post<any>(endPoint, model)
                    .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }

  create(model: any): Observable<any> {
    const endPoint = `${this.baseURL.create}`;
    return this.http.post<any>(endPoint, model)
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }

  detail(id:any): Observable<any> {
    const endPoint = `${this.baseURL.detail}/${id}`;
    return this.http.get<any>(endPoint)
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }

  update(id:any, model: any): Observable<any> {
    const endPoint = `${this.baseURL.update}/${id}`;
    return this.http.patch<any>(endPoint, model)
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }

  delete(id:any): Observable<any> {
    const endPoint = `${this.baseURL.delete}/${id}`;
    return this.http.delete<any>(endPoint)
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }
  searchItems(params: any): Observable<any> {
    const endPoint = `${this.baseURL.searchItems}`;
    return this.http.post<any>(endPoint, params)
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }

  updateDisplayOptions(id:any, model: any): Observable<any> {
    model.loadingService = false;
    const endPoint = `${this.baseURL.updateDisplayOptions}/${id}`;
    return this.http.post<any>(endPoint, model)
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }
  cloneScope(id:any, model: any): Observable<any> {
    model.loadingService = false;
    const endPoint = `${this.baseURL.cloneScope}/${id}`;
    return this.http.post<any>(endPoint, model)
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }
  getRevisionBidders(id:any, model: any = {}): Observable<any> {
    const endPoint = `${this.baseURL.getRevisionBidders}/${id}`;
    return this.http.post<any>(endPoint, model)
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }
  getCalculations(id:any): Observable<any> {
    const endPoint = `${this.baseURL.calculations}/${id}`;
    return this.http.get<any>(endPoint, {params: {scope: 1}})
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }
  getCloneScopeBy(id:any): Observable<any> {
    const endPoint = `${this.baseURL.getCloneScopes}/${id}`;
    return this.http.get<any>(endPoint)
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }
  errorHandler(error: HttpErrorResponse) {
    return observableThrowError(error.error || 'Server Error');
  }

  errorHandlerMessage(error: HttpErrorResponse) {
    return observableThrowError(error.error || 'Server Error');
  }
}
