<div class="task-" [formGroup]="noteForm">

  <!-- Notes -->
  <div class="note-wrapper">

    <!-- Note Header Area -->
    <div class="note-header">
      <div class="row">
        <div class="col-md-1 p-17">
          <div class="close-btn" (click)="close()">
            <img src="assets/img/cross_icon.svg">
          </div>
        </div>
        <div class="col-md-6">
          <div class="title">
            <h3>{{noteId ? 'Update' : 'New'}} Note</h3>
          </div>
        </div>
      </div>
    </div>


    <!-- Note Body -->
    <div class="note-body">
      <div class="card note-card">
        <div class="card-header note-card-header">
          <div class="note-info-title">
            <div class="row">

              <ng-container *ngIf="isNoteModule">
                <div class="col-md-12 mb-4">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group col-12">
                        <ng-select id="scope" [items]="modulesList"  placeholder="Select Module" [closeOnSelect]="true"
                          bindLabel="name" (change)="selectedModule($event)"  appendTo="body"                          >
                        </ng-select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <ng-container *ngIf="!showSearchInput">
                        <div class="cross-icon">
                          <span class="search-text"> {{this.selectedItem?.data ?? 'Search'}}</span>
                          <span class="cross"  (click)="updateSearchInputFlag()">&#10005;</span>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="showSearchInput">
                        <app-search-field [searchAbleDataService]="taskDataService"
                          [searchAbleFunctionName]="'searchTaskReferenceNumber'" [searchAbleParams]="searchParams"
                          [labelKey]="'data'" [searchPlaceHolder]="'Search reference number'" (onSelectValue)="onItemSelect($event)">
                        </app-search-field>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </ng-container>

              <div class="col-md-9">
                <input type="text" formControlName="name" class="form-control" placeholder="Enter Note Name" >

              </div>
              <div class="col-md-3">
                <div class="info-panel-important">
                  <mat-checkbox formControlName="flag" (change)="changeFlag($event)"></mat-checkbox>
                  <p>Flag</p>
                  <img src="../../../../../assets/img/general_icons/flag-24.png" alt="fire icon" *ngIf="CONST_FLAG">
                  <img src="../../../../../assets/img/general_icons/flag.png" alt="fire icon" *ngIf="!CONST_FLAG">
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Description Area -->
        <div class="card-body note-card-body">
          <div class="description-editor">
              <textarea class="text-area" formControlName="description" placeholder="Type here...." [mentionConfig]="{labelKey:'full_name'}"
              (itemSelected)="getSelectedUser($event)" (searchTerm)="getUsers($event)" [mention]="mentions" labe></textarea>
          </div>


          <div class="tabs bid_tabs_cards">
            <mat-tab-group>
              <mat-tab label="File" class="nav-link">
                <ng-template matTabContent>
                  <div class="tabs-bg">
                      <app-attachment  [fileTypes]="allowedFileTypes" [dropZoneId]="'note_files_drop'" [multipleUpload]="true"
                      [multipleUploadData]="attachmentsArr"  (callback)="getFileID($event)" [module_id]="NOTE_MODULE_ID"></app-attachment>
                  </div>
                </ng-template>
              </mat-tab>

              <mat-tab label="@ Mention" class="nav-link">
                <ng-template matTabContent>
                  <div class="tabs-bg">
                    <mat-chip-list>
                      <mat-chip
                        *ngFor="let user of selectedMentionedUsers; let index=index"><span>{{user?.user?.full_name}}</span><span
                          class="cursor-pointer ml-2" (click)="remove(index,selectedMentionedUsers)"><img
                            src="assets/img/cross_icon.svg" alt=""></span> </mat-chip>
                    </mat-chip-list>
                  </div>
                </ng-template>
              </mat-tab>

            </mat-tab-group>
          </div>

          <!-- Note Option -->
          <!-- <div class="note-options">
            <div class="note-options-item">
              <div class="row">
                <div class="col-md-2">
                  <p class="item-name">Responsible Person</p>
                </div>
                <div class="col-md-10">
                  <div class="row note-item-field-inner">
                    <div class="col-md-7 note-item-field">
                      <div class="added-persons">
                        <mat-chip-list>
                          <mat-chip
                            *ngFor="let user of responsibleUsersArray; let index=index"><span>{{user.full_name}}</span><span
                              class="cursor-pointer ml-2" (click)="remove(index,responsibleUsersArray)"><img
                                src="assets/img/cross_icon.svg" alt=""></span> </mat-chip>
                        </mat-chip-list>
                      </div>
                      <div class="add-more-btn" (click)="openUsersPopup($event,CONST_RESPONSIBLE_PERSON_TYPE)">+ Add
                        more</div>
                    </div>
                    <div class="col-md-5">
                      <span class="note-dashed-items">
                        <span class="note-dashed-link-inner" (click)="hideShowCreatedBy()">Created by</span>
                        <span class="note-dashed-link-inner" (click)="hideShowObserver()">Observer</span>
                        <span class="note-dashed-link-inner" (click)="hideShowParticipant()">Participant</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <ng-container *ngIf="CONST_CREATED_BY">
              <div class="note-options-item">
                <div class="row">
                  <div class="col-md-2">
                    <p class="item-name">Created by</p>
                  </div>
                  <div class="col-md-10">
                    <div class="row note-item-field-inner">
                      <div class="col-md-11 note-item-field">
                        <div class="added-persons">
                          <mat-chip-list>
                            <mat-chip *ngIf="createdByArray"><span>{{createdByArray.full_name}}</span>
                            </mat-chip>
                          </mat-chip-list>
                        </div>
                        <div class="add-more-btn" (click)="openUsersPopup($event,CONST_CREATED_BY_TYPE)">Change</div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="CONST_OBSERVER">
              <div class="note-options-item">
                <div class="row">
                  <div class="col-md-2">
                    <p class="item-name">Observer</p>
                  </div>
                  <div class="col-md-10">
                    <div class="row note-item-field-inner">
                      <div class="col-md-11 note-item-field">
                        <div class="added-persons">
                          <mat-chip-list>
                            <mat-chip
                              *ngFor="let user of observerArray; let index=index"><span>{{user.full_name}}</span><span
                                class="cursor-pointer ml-2" (click)="remove(index,observerArray)"><img
                                  src="assets/img/cross_icon.svg" alt=""></span> </mat-chip>
                          </mat-chip-list>
                        </div>
                        <div class="add-more-btn" (click)="openUsersPopup($event,CONST_OBSERVER_TYPE)">+ Add more</div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="CONST_PARTICIPANT">
              <div class="note-options-item">
                <div class="row">
                  <div class="col-md-2">
                    <p class="item-name">Participant</p>
                  </div>
                  <div class="col-md-10">
                    <div class="row note-item-field-inner">
                      <div class="col-md-11 note-item-field">
                        <div class="added-persons">
                          <mat-chip-list>
                            <mat-chip
                              *ngFor="let user of participantArray; let index=index"><span>{{user.full_name}}</span><span
                                class="cursor-pointer ml-2" (click)="remove(index,participantArray)"><img
                                  src="assets/img/cross_icon.svg" alt=""></span> </mat-chip> </mat-chip-list>
                        </div>
                        <div class="add-more-btn" (click)="openUsersPopup($event,CONST_PARTICIPANT_TYPE)">+ Add more
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </ng-container>
          </div> -->
        </div>
      </div>
    </div>
  </div>

  <!-- Note Footer Area -->
  <div class="note-footer">
    <div class="note-buttons">
      <button type="button" class="btn add-note-button" (click)="save()">{{noteId ? 'UPDATE' : 'ADD'}} Note</button>
      <button type="button" class="btn cancel-btn" (click)="close()">Cancel</button>
    </div>
  </div>
</div>
