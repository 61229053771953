import {HostListener, Injectable} from '@angular/core';
import {MatStepper} from "@angular/material/stepper";
import {SharedDataService} from "../modules/shared/shared.data.service";
// import {NgControl} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  public expansionStep: number = -1;
  constructor(
    public sharedDataService: SharedDataService
  ) {}


  onInputChange(event: any, backspace: boolean) {
    let newVal = event.replace(/[^0-9.]/g, '');
    const valueParts = newVal.split(".");
    newVal = valueParts[0];

    if (backspace && newVal.length <= 6) {
      newVal = newVal.substring(0, newVal.length - 1);
    }

    if (newVal.length === 0) {
      newVal = "";
    } else if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,3})/, "$1");
    } else if (newVal.length <= 4) {
      newVal = newVal.replace(/^(\d{0,1})(\d{0,3})/, "$1,$2");
    } else if (newVal.length <= 5) {
      newVal = newVal.replace(/^(\d{0,2})(\d{0,3})/, "$1,$2");
    } else if (newVal.length <= 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, "$1,$2");
    } else if (newVal.length <= 7) {
      newVal = newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,4})/, "$1,$2,$3");
    } else if (newVal.length <= 8) {
      newVal = newVal.replace(/^(\d{0,2})(\d{0,3})(\d{0,4})/, "$1,$2,$3");
    } else if (newVal.length <= 9) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, "$1,$2,$3");
    } else {
      newVal = newVal.substring(0, 10);
      newVal = newVal.replace(
        /^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/,
        "$1,$2,$3,$4"
      );
    }
    if (valueParts[1] != undefined){
      newVal = newVal + '.' + valueParts[1];
    }
    // this.ngControl?.valueAccessor?.writeValue(newVal);
    return newVal;
  }
  formatCurrencyToNumber(value: any){
    if (isNaN(value)){
      return value ? parseFloat(value.replace(/,/g, '')) : 0;
    }
    return parseFloat(value);
  }

   sanitizeNumber(value: any){
    return value ? value : 0;
   }
   formatToCurrency(value: number, decimalPlaces: number =2, currencySign: string = '$'): string {
    // Convert the number to a string with specified decimal places
    let formattedValue = value.toFixed(decimalPlaces);

    // Add the currency sign before the formatted number
    return `${currencySign}${formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  }



  onFocusOut(value: any){
    let newVal = value.replace(/,/g, '');
    newVal = parseFloat(newVal);
    newVal = newVal.toFixed(2);
    this.onInputChange(newVal,  false);
  }
  toNumber(val: any) {
    let valArr = val.split("");
    let valFiltered = valArr.filter((x: any) => !isNaN(x));
    let valProcessed = valFiltered.join("");
    return valProcessed;
  }
  removeHttp(url: any){
    url = url.replace(/^https?:\/\//, '');
    url = url.replace(/^http?:\/\//, '');
    return url;
  }

   Integernumber(event: Event) {
    const input = event.target as HTMLInputElement;
    let val = input.value;
    val = val.replace(/^0+|[^\d.]/g, '');
    if ((event instanceof KeyboardEvent) && (event as KeyboardEvent).key === '-' && val.length > 0) {
      event.preventDefault();
      return;
    }
    input.value = val;
  }

  validateMaxValue(event: Event,maxValue: any) {
    const inputElement = event.target as HTMLInputElement;
    const currentValue = inputElement.value;

    // If the value is greater than 12, set the value to 12
    if (parseInt(currentValue, 10) > maxValue) {
      inputElement.value = maxValue;
    }
  }
  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
  targetBlank(url: any) {
    window.open(url,'_blank');
  }
  stepperGoBack(stepper: MatStepper) {
    stepper.previous();
  }
  //TAbS Expansion methods
  toggleMatExpansion(index: number){
    this.expansionStep = this.expansionStep == index ? -1 : index;
  }
  isMatExpansionOpen(index: number){
    return this.expansionStep === index
  }
  // END TAbS Expansion methods


  checkLoadMoreOptionVisibility(isIncrement: boolean,proposalInfoList:any,meta:any,param:any) {
    let showLoadMoreOption:boolean=false
    if (proposalInfoList.length > 0) {
      if (param.per_page <= meta?.total) {
        isIncrement ? (param.per_page += 10) : param.per_page;
        showLoadMoreOption = true;
      } else {
        showLoadMoreOption = false;
      }
    }else{
      showLoadMoreOption = false;
    }
    return showLoadMoreOption
  }

   getCommaSeparatedString(arr: any[], key: string,separator: string = ','): string {
    // Check if the array is null, undefined, or empty, or if the key is invalid
    if (!arr || arr.length === 0 || !key) {
      return '';
    }

    // Filter out any items where the key doesn't exist or is undefined/null
    const validItems = arr.filter(item => item[key] !== undefined && item[key] !== null);

    // If there are no valid items, return an empty string
    if (validItems.length === 0) {
      return '';
    }

    // Map the valid items to their respective key values and join them into a comma-separated string
    return validItems.map(item => item[key]).join(separator);
  }

  getTemplates(apiUrl:any, module_id:any,data: any) {
    // this will be uncommented when backend ready
    Object.values(data?.templates)?.map((templateValue:any,) => {
      const url = `${apiUrl}?id=${module_id}&template_id=${templateValue}&output=${data?.type}`
      window.open(url, '_blank');
    })
  }


  // todo I will remove this after implement getColumnConfiguration in all listing tables.
  getColumnVisibility(columnName: string, module: string, defaultValue: boolean = false): boolean {
    const savedColumnSettings = localStorage.getItem(module);

    if (savedColumnSettings) {
      let columnSettings = JSON.parse(savedColumnSettings);
      if (!columnSettings || !columnSettings.columns) {
        return defaultValue; // Default to false if no settings are found
      }

      const column = columnSettings.columns.find(
        (col: any) => col.dataField === columnName || col.name === columnName
      );
      return column ? column.visible : defaultValue;

    }

    return defaultValue;
  }

  // Convert Object to Array
  convertObjectToArray(obj: any): any[] {
    // Convert object values to an array
    return Object.values(obj);
  }

  isPermissionAvailable(requiredPermission: string){

    if (requiredPermission !== 'DONOTAPPLY') {
      const permissions = this.sharedDataService.getPermissions();
      const isSuperAdmin = this.sharedDataService.getIsCurrentUserSuperAdmin()
      if (!isSuperAdmin){
        if (permissions.includes(requiredPermission)) {
          return  true;
        } else {
          return  false;
        }
      }

      if(requiredPermission == 'disable'){
        return false;
      }

    }
    return true
  }


  pluck(arr: any[], key: string): any[] {
    // Check if the first parameter is an array and contains objects
    if (!Array.isArray(arr) || !arr.every(item => typeof item === 'object')) {
      return [];
    }

    // Return an array of values corresponding to the specified key
    return arr.map(item => item[key]).filter(value => value !== undefined && value !== null);
  }
}
