import {environment} from "../../../../environments/environment";

function createUrl(actionName: string): string {
  return `${environment.apiBaseUrl}${environment.apiUrl}${environment.apiVersion}${actionName}`;
}

export const BidApiResources = {
  list:  createUrl('bids'),
  search:  createUrl('bids/search'),
  create:  createUrl('bids/create'),
  addBidder:  createUrl('bids/add/bidder'),
  removeBidder:  createUrl('bids/remove/bidder'),
  changeStatus:  createUrl('bids/change/status'),
  onHold:createUrl('bids/on-hold'),
  updateUnionOptions:createUrl('bids/update-union-options'),

  detail:  createUrl('bids'),
  update:  createUrl('bids'),
  delete:  createUrl('bids'),
};
