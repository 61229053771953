import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs';
import { HelperService } from '../../../../services/helper.service';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-scrollable-search-field',
  templateUrl: './scrollable-search-field.component.html',
  styleUrls: ['./scrollable-search-field.component.scss'],
})
export class ScrollableSearchFieldComponent implements OnInit, OnDestroy, OnChanges
{
  @ViewChild(MatSelect) matSelect: MatSelect | any;
  @Input() dataService: any;
  @Input() dataServiceFunctionName: string = 'list';
  @Input() sortBy = '';
  @Input() sortOrder = '';
  @Input() noOfRecPerPage: number = 20;
  @Input() pageKey: string = 'per_page';
  @Input() searchKey: string = 'search';
  @Input() searchPlaceHolder = 'Type to search';
  @Input() labelKey = 'title';
  @Input() defaultValue = '';
  @Input() messageNoEntriesFound = 'No results found!';
  @Input() addButtonLabel = 'Add Customer';
  @Input() formDataMapper: any = {}; // send as {id:id, name:company_name}
  @Input() keyNameToShowWhenSelected = 'name';
  @Input() inputVal = '';
  @Input() showError = true;
  @Input() markCtrlTouched: boolean | undefined = false;
  @Input() addNewOnNoEntriesFound: boolean = false;
  @Input() moduleId: any
  @Input() referenceId:any
  @Input() disabled:boolean=false
  @Input() additionalParams: any ={};

  filteredResult = [];
  isLoading = false;

  @Output() selectedValueChange = new EventEmitter();
  @Output() addButtonCallback = new EventEmitter();

  ctrl: FormControl = new FormControl();
  selectSearchCtrl: FormControl = new FormControl();
  searchVal = '';

  lastPage = 1;
  currentPage = 0;
  showAddRecButton = false;

  apiCallSubscription: any;
  changedValue: any;

  constructor(public helperService: HelperService) {}

  ngOnInit() {
    this.fetchData();
    this.selectSearchCtrl.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(1000),
        map((val) => (val ? val.trim() : ''))
      )
      .subscribe((trimmedVal: string) => {
        if (trimmedVal || this.searchVal) {
          this.removeOngoingAPIcall();
          this.resetPagination();
          this.searchVal = trimmedVal;
          this.fetchData();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['markCtrlTouched'] &&
      !changes['markCtrlTouched'].firstChange &&
      changes['markCtrlTouched'].currentValue
    ) {
      this.selectSearchCtrl.markAsTouched();
    }
  }

  ngOnDestroy(): void {}

  onChange(changedData: any): void {
    // parse data as per form data mapper to store in form control
    const parsedData: any = {};
    for (const index in this.formDataMapper) {
      parsedData[index] = changedData[this.formDataMapper[index]];
    }
    this.inputVal = parsedData[this.keyNameToShowWhenSelected];
    this.changedValue = parsedData;
    this.selectedValueChange.emit(parsedData);
  }

  clearInput() {
    this.selectSearchCtrl.setValue('');
    this.ctrl.setValue('');
    this.inputVal = '';
    this.changedValue = null;
    this.selectedValueChange.emit(null);
    this.selectSearchCtrl.markAsTouched();
  }

  fetchData(): void {
    if (this.lastPage === this.currentPage || this.isLoading) {
      return;
    }

    this.isLoading = true;
    let data: any = {
      [this.pageKey]: this.noOfRecPerPage,
      [this.searchKey]: this.searchVal,
      loadingService: false,
      page:this.currentPage < this.lastPage ? this.currentPage + 1 : this.lastPage,
    };
    if (this.sortBy) {
      data['sort_by'] = this.sortBy;
    }
    if (this.sortOrder) {
      data['sort_order'] = this.sortOrder;
    }
    if(this.moduleId){
      data['moduleId']=this.moduleId
    }
    if(this.referenceId){
      data['referenceId']=this.referenceId
    }
    // console.log('additionalParams', data.concat(this.additionalParams));
    data = {...data,...this.additionalParams};

    this.apiCallSubscription = this.dataService[this.dataServiceFunctionName](
      data
    ).subscribe((data: any) => {
      this.filteredResult = this.filteredResult.concat( data.data.data ? data.data.data : data.data);
      const metaData = data.data.meta ? data.data.meta : data.meta;
      this.currentPage = metaData.current_page;
      this.lastPage = metaData.last_page;

      if (!this.filteredResult || !this.filteredResult.length) {
        this.showAddRecButton = true;
      }
      this.isLoading = false;
    });
  }

  createRecord() {
    this.addButtonCallback.emit();
    // alert('Feature Under Development..');
  }

  hasError() {
    if (!this.showError) {
      return false;
    }
    return (
      (this.selectSearchCtrl.dirty || this.selectSearchCtrl.touched) &&
      !this.changedValue && !this.inputVal
    );
  }

  private resetPagination() {
    this.currentPage = 0;
    this.lastPage = 1;
    this.filteredResult = [];
  }

  private removeOngoingAPIcall() {
    if (this.apiCallSubscription) {
      this.isLoading = false;
      this.apiCallSubscription.unsubscribe();
    }
  }
}
