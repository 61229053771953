import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { JsonService } from './json.service';
import { tap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})


export class DataTableConfigurationService {

  constructor(
    private http: HttpClient,
    private jsonService: JsonService,
  ) {
  }

  resetInitDataTableConfiguration(module_name: string)
  {
    let item = localStorage.getItem(module_name);
    if(item) {
      let config = JSON.parse(item)
      config.pageIndex = 0;
      config.searchText = "";

      config.columns.forEach((column: any) => {
        if (column.hasOwnProperty('filterValue')) {
          column.filterValue = ''; // or null, depending on what you need
        }
      });

      localStorage.setItem(module_name,  this.jsonService.doStringify(config))
    }
  }

  saveDataTableConfiguration(model: any): Observable<any> {
    const endPoint = `${this.createUrl('data-table-configurations/create')}`;
    model.loadingService = false
    return this.http.post<any>(endPoint, model)
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }

  getDataTableConfiguration(): Observable<any> {
    const endPoint = `${this.createUrl('data-table-configurations')}`;
    return this.http.get<any>(endPoint)
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }


  errorHandlerMessage(error: HttpErrorResponse) {
    return observableThrowError(error.error || 'Server Error');
  }

  createUrl(actionName: string): string {
    return `${environment.apiBaseUrl}${environment.apiUrl}${environment.apiVersion}${actionName}`;
  }

  getColumnConfiguration(columnName: string, property: string, moduleName: string, defaultValue: any = false): boolean {
    const savedColumnSettings = localStorage.getItem(moduleName);

    if (savedColumnSettings) {
      let columnSettings = JSON.parse(savedColumnSettings);
      if (!columnSettings || !columnSettings.columns) {
        return defaultValue; // Default to false if no settings are found
      }

      const column = columnSettings.columns.find(
        (col: any) => col.dataField === columnName || col.name === columnName
      );
      return column ? column[property] : defaultValue;

    }

    return defaultValue;
  }

}
